var axios = require('axios');

export default class MainService {

    constructor() {
        var token = JSON.parse(localStorage.getItem('token'));
        axios.defaults.baseURL = process.env.VUE_APP_MAIN_SERVICE;
        if (token) {
            axios.defaults.headers.common = {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        }else{
            axios.defaults.headers.common = {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        }
    }

    getToken() {
        return axios.defaults.headers.common;
    }

    login(item) {
        return axios.post('api/login', item);
    }

    uploadFile(item) {
        return axios.post('api/uploadFile', item);
    }

    loginToken365(item) {
        return axios.post('api/loginToken365', item);
    }

    indexPersona() {
        return axios.defaults.baseURL + 'api/Persona/index';
    }

    showPersona(id) {
        return axios.get('api/Persona/show?id=' + id);
    }

    listPersona() {
        return axios.get('api/Persona/list');
    }

    storePersona(item) {
        return axios.post('api/Persona/store', item);
    }

    destroyPersona(item) {
        return axios.post('api/Persona/destroy', item);
    }

    changePassword(item) {
        return axios.post('api/Persona/changePassword', item);
    }

    storeLogin(item) {
        return axios.post('api/storeLogin', item);
    }

    showUnidadAcademica(id) {
        return axios.get('api/UnidadAcademica/show?id=' + id);
    }

    indexUnidadAcademica() {
        return axios.defaults.baseURL + 'api/UnidadAcademica/index';
    }

    listUnidadAcademica() {
        return axios.get('api/UnidadAcademica/list');
    }

    storeUnidadAcademica(item) {
        return axios.post('api/UnidadAcademica/store', item);
    }

    destroyUnidadAcademica(item) {
        return axios.post('api/UnidadAcademica/destroy', item);
    }

    showRol(id) {
        return axios.get('api/Rol/show?id=' + id);
    }

    indexRol() {
        return axios.defaults.baseURL + 'api/Rol/index';
    }

    listRol() {
        return axios.get('api/Rol/list');
    }

    storeRol(item) {
        return axios.post('api/Rol/store', item);
    }

    destroyRol(item) {
        return axios.post('api/Rol/destroy', item);
    }

    // NUEVAS PAGINAS
    
   
    indexPrograma() {
        return axios.defaults.baseURL + 'api/Programa/index';
    }
   
    showPrograma(id) {
        return axios.get('api/Programa/show?id=' + id);
    }

    listPrograma() {
        return axios.get('api/Programa/list');
    }

    storePrograma(item) {
        return axios.post('api/Programa/store', item);
    }

    destroyPrograma(item) {
        return axios.post('api/Programa/destroy', item);
    }

    
    //--------------------REPARTICION--------------------
    indexReparticion() {
        return axios.defaults.baseURL + 'api/Reparticion/index';
    }
   
    showReparticion(id) {
        return axios.get('api/Reparticion/show?id=' + id);
    }

    listReparticion() {
        return axios.get('api/Reparticion/list');
    }

    storeReparticion(item) {
        return axios.post('api/Reparticion/store', item);
    }

    destroyReparticion(item) {
        return axios.post('api/Reparticion/destroy', item);
    }

    //--------------------TIPO-DOCUMENTO--------------------
    indexTipoDocumento() {
        return axios.defaults.baseURL + 'api/TipoDocumento/index';
    }
   
    showTipoDocumento(id) {
        return axios.get('api/TipoDocumento/show?id=' + id);
    }

    listTipoDocumento() {
        return axios.get('api/TipoDocumento/list');
    }

    storeTipoDocumento(item) {
        return axios.post('api/TipoDocumento/store', item);
    }

    destroyTipoDocumento(item) {
        return axios.post('api/TipoDocumento/destroy', item);
    }


     //-----------------AREA INVESTIGACION-----------------------
     indexAreaInvestigacion() {
        return axios.defaults.baseURL + 'api/AreaInvestigacion/index';
    }
   
    showAreaInvestigacion(id) {
        return axios.get('api/AreaInvestigacion/show?id=' + id);
    }

    listAreaInvestigacion() {
        return axios.get('api/AreaInvestigacion/list');
    }

    storeAreaInvestigacion(item) {
        return axios.post('api/AreaInvestigacion/store', item);
    }

    destroyAreaInvestigacion(item) {
        return axios.post('api/AreaInvestigacion/destroy', item);
    }

     //-----------------DOCUMENTO ACTIVO-----------------------
     indexDocumentoActivo() {
        return axios.defaults.baseURL + 'api/DocumentoActivo/index';
    }
   
    showDocumentoActivo(id) {
        return axios.get('api/DocumentoActivo/show?id=' + id);
    }

    listDocumentoActivo() {
        return axios.get('api/DocumentoActivo/list');
    }

    storeDocumentoActivo(item) {
        return axios.post('api/DocumentoActivo/store', item);
    }

    destroyDocumentoActivo(item) {
        return axios.post('api/DocumentoActivo/destroy', item);
    }

    //-----------------DOCUMENTO-----------------------
    indexDocumento() {
        return axios.defaults.baseURL + 'api/Documento/index';
    }
   
    showDocumento(id) {
        return axios.get('api/Documento/show?id=' + id);
    }

    storeDocumentoMasivo(data) {
        return axios.post('api/Documento/storeGroup' , data);
    }

    filtrarProgramaDocumento(filtros) {
        return axios.post('api/Documento/filtrarPrograma', filtros);
    }

    listDocumento() {
        return axios.get('api/Documento/list');
    }

    storeDocumento(item) {
        return axios.post('api/Documento/store', item);
    }

    destroyDocumento(item) {
        return axios.post('api/Documento/destroy', item);
    }

    select2(qp) {
        return axios.get('api/Documento/select2?qp=' + qp);
    }
    
   

    listVistaPostgradoPrograma() {
        return axios.get('api/listVistaPostgrado');
    }

    listVistaPostgradoporUA(params) {
        return axios.get('api/listVistaPostgradoporUA', {params: params});
    }
    generarPDF(params) {
        return axios.get('api/Documento/reporteUA', {params: params});
    }

    listVistaPostgrado(params) {
        return axios.get('api/listVistaPostgrado', {params: params});
    }
    
    listVistaGrado(params) {
        return axios.get('api/listVistaGrado', {params: params});
    }
}