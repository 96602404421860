<template>
    <v-list nav dense>
        <v-list-item-group>

<!-- PRIMER MENU -->
        <v-list-group>
            <template v-slot:activator>
                <v-list-item-icon>
                    <v-icon>mdi mdi-account-settings-variant</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>Organización</v-list-item-title>
                </v-list-item-content>
            </template>

            <!-- Persona -->
             <router-link
                class="black--text text--accent-4"
                to="Persona"
                active-class="blue--text text--accent-4"
                v-if="persona.Rol==1"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon>mdi-account-multiple</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>{{
                            $t("nav.Persona")
                        }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link>
        
            <!-- Unidad Académica -->
            <router-link
                class="black--text text--accent-4"
                to="UnidadAcademica"
                active-class="blue--text text--accent-4"
                v-if="persona.Rol==1"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon>mdi-school</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>{{
                            $t("nav.UnidadAcademica")
                        }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link>

            <!-- Rol -->
            <router-link
                class="black--text text--accent-4"
                to="Rol"
                active-class="blue--text text--accent-4"
                v-if="persona.Rol==1"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon>mdi-key</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>{{
                            $t("nav.Rol")
                        }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link>

            

            <!-- PERFIL -->
             <router-link
                class="black--text text--accent-4"
                to="Profile"
                active-class="blue--text text--accent-4"
                v-if="persona.Rol<5"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon>mdi-account-circle</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>{{
                            $t("nav.Profile")
                        }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link>
        </v-list-group>


 <!-- SEGUNDO MENU -->
        <v-list-group v-if="persona.Rol<3">
            <template v-slot:activator>
                <v-list-item-icon>
                    <v-icon>ion ion-md-attach</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>Documento</v-list-item-title>
                </v-list-item-content>
            </template>

            <!-- Persona -->
            <router-link
                class="black--text text--accent-4"
                to="Reparticion"
                active-class="blue--text text--accent-4"
                v-if="persona.Rol<3"
                
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon>ion ion-md-filing</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>{{
                            $t("nav.Reparticion")
                        }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link> 

        <!-- Tipo Documento -->

            <router-link
                class="black--text text--accent-4"
                to="TipoDocumento"
                active-class="blue--text text--accent-4"
                v-if="persona.Rol<3"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon>ion ion-ios-bookmarks</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>{{
                            $t("nav.TipoDocumento")
                        }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link> 
 <!-- Area Investigacion -->
            <router-link
                class="black--text text--accent-4"
                to="AreaInvestigacion"
                active-class="blue--text text--accent-4"
                v-if="persona.Rol<3"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon>ion ion-ios-photos</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>{{
                            $t("nav.AreaInvestigacion")
                        }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link> 

     <!-- Documento Activo -->

            <!-- <router-link
                class="black--text text--accent-4"
                to="DocumentoActivo"
                active-class="blue--text text--accent-4"
                v-if="persona.Rol<4"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon>fas fa-check</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>{{
                            $t("nav.DocumentoActivo")
                        }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link>  -->
        </v-list-group>

 <!-- TERCER MENU -->
        

<!-- CUARTO MENU -->
        <v-list-group v-if="persona.Rol<3">
            <template v-slot:activator >
                <v-list-item-icon>
                    <v-icon>mdi mdi-script</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>Posgrado</v-list-item-title>
                </v-list-item-content>
            </template>

            <router-link
                class="black--text text--accent-4"
                to="Programa"
                active-class="blue--text text--accent-4"
                v-if="persona.Rol<3"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon>ion ion-ios-today</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>{{
                            $t("nav.Programa")
                        }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link> 

        </v-list-group>




<!-- -->

            <!-- <router-link
                class="black--text text--accent-4"
                to="Persona"
                active-class="blue--text text--accent-4"
                v-if="persona.Rol==1"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon>mdi-account-multiple</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>{{
                            $t("nav.Persona")
                        }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link> -->

            <!-- <router-link
                class="black--text text--accent-4"
                to="UnidadAcademica"
                active-class="blue--text text--accent-4"
                v-if="persona.Rol==1"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon>mdi-school</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>{{
                            $t("nav.UnidadAcademica")
                        }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link> -->

            <!-- <router-link
                class="black--text text--accent-4"
                to="Rol"
                active-class="blue--text text--accent-4"
                v-if="persona.Rol==1"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon>mdi-key</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>{{
                            $t("nav.Rol")
                        }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link> -->

            <!-- NUEVAS TABLAS -->

            
            <router-link
                class="black--text text--accent-4"
                to="Documento"
                active-class="blue--text text--accent-4"
                v-if="persona.Rol<5"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon>mdi mdi-account-card-details</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>{{
                            $t("nav.Documento")
                        }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link> 

            <!-- REPORTES -->
            <router-link
                class="black--text text--accent-4"
                to="Reporte"
                active-class="blue--text text--accent-4"
                v-if="persona.Rol<4"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon>ion ion-md-clipboard</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>{{
                            $t("nav.Reporte")
                        }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link>

            <router-link
                class="black--text text--accent-4"
                to="Repositorio"
                active-class="blue--text text--accent-4"
                v-if="persona.Rol<5"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon>fas fa-home</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>{{
                            $t("nav.Repositorio")
                        }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link>

           

            <!-- REPOSITORIO DE POSGRADO -->
            
            <div class="container">
               <v-btn
                depressed
                block
                color="#17a2b8"
                class="p-1 text-white"
                href="http://repositoriogrado.servicios.emi.edu.bo/"

               >
                   Trabajos de Grado
               </v-btn>
            </div>

           
        </v-list-item-group>
    </v-list>
</template>

<script>
export default {
    name: "MenuVuetify",
    data() {
        return {
            msg: "MenuVuetify",
            persona: {},
        };

    },
    mounted() {
        this.persona = JSON.parse(localStorage.getItem('persona'));
    }

};
</script>